import { zodResolver } from '@hookform/resolvers/zod';
import useAuth from 'src/hooks/useAuth';
import {
  PolicyState,
  useClaimsPresignedUploadLinksLazyQuery,
  useFleetDetailsQuery,
  usePoliciesQuery,
} from 'src/types/graphql-types';
import { useGetDOTFromParams } from 'src/hooks/useGetDOTFromParams';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  ClaimSchema,
  ClaimType,
  NewClaimContext,
} from '@nirvana/ui-kit/src/components/NewFnolForm/contexts/newClaim';

export const NewClaimProvider: React.FC = ({ children }) => {
  const {
    formState,
    getValues,
    handleSubmit,
    register,
    resetField,
    setValue,
    watch,
  } = useForm<ClaimType>({
    mode: 'onChange',
    resolver: zodResolver(ClaimSchema),
    defaultValues: {
      reporter: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      lossDate: new Date(),
      police: {
        onTheScene: 'yes',
      },
      otherVehiclesInvolved: 'yes',
      ownVehiclesInvolved: 'yes',
      noticeType: 'initiateClaim',
      insuredVehicleVins: [''],
      otherVehicleVins: [''],
      injureds: 'yes',
      attachments: [],
    },
  });

  const { user } = useAuth();
  const { dotNumber } = useGetDOTFromParams();
  const { data: fleetData } = useFleetDetailsQuery({
    variables: { reportId: dotNumber },
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.name) {
      const [firstName, lastName] = user.name.split(' ');
      setValue('reporter.firstName', firstName);
      setValue('reporter.lastName', lastName);
    }
    if (user.email) {
      setValue('reporter.email', user.email);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (fleetData) {
      setValue('insuredDOT', dotNumber);
      setValue('insuredName', fleetData.fleetSafetyReport?.name || '');
    }
  }, [dotNumber, fleetData, setValue]);

  const lossDate = watch('lossDate');
  const insuredDOT = watch('insuredDOT');

  const { data: policiesData, loading: isLoadingPolicies } = usePoliciesQuery({
    skip: !lossDate || !insuredDOT,
    variables: {
      activeDateIn: lossDate.toISOString(),
      dotNumber: insuredDOT,
      policyStates: [
        PolicyState.Active,
        PolicyState.Expired,
        PolicyState.CancellationFiled,
      ],
    },
  });

  const [getPresignedUploadLinks] = useClaimsPresignedUploadLinksLazyQuery();

  const signFiles = useCallback(
    async (files: File[]) => {
      const { data, error } = await getPresignedUploadLinks({
        variables: {
          fileNames: files.map(({ name }) => name),
        },
      });
      if (error) {
        throw error;
      }
      return (data?.claimsPresignedUploadLinks ?? []).map(({ url, key }) => ({
        url,
        key,
      }));
    },
    [getPresignedUploadLinks],
  );

  const viewAllClaimsLink = `/${dotNumber}/claims`;

  return (
    <NewClaimContext.Provider
      value={{
        formState,
        getValues,
        handleSubmit,
        register,
        resetField,
        setValue,
        watch,
        isLoadingPolicies,
        policies: policiesData?.policies,
        signFiles,
        viewAllClaimsLink,
      }}
    >
      {children}
    </NewClaimContext.Provider>
  );
};
