import { Factory } from 'fishery';
import { subMonths } from 'date-fns';
import { ScoreTrendItem } from '../../../src/types/graphql-types';

const safetyScores = [
  63, 61, 47, 50, 45, 42, 30, 38, 35, 30, 35, 30, 33, 31, 37, 30, 35, 32, 30,
  38, 35, 38, 35, 30,
];

export const safetyScoresFactory = Factory.define<Partial<ScoreTrendItem>[]>(
  () => {
    const currentDate = new Date();
    return safetyScores.map((score, index) => {
      return {
        __typename: 'ScoreTrendItem',
        score,
        isConfidentScore: true,
        lowConfidenceReason: null,
        timestamp: subMonths(currentDate, index).toISOString(),
      };
    });
  },
);
