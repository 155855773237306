import { useContext, useEffect, useRef, useState, ReactNode } from 'react';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { AppBar, Button, CircularProgress, Container } from '@material-ui/core';
import { HorizontalStepper, VerticalStepper, Show } from '@nirvana/ui-kit';

import { ClaimType, NewClaimContext } from './contexts/newClaim';

import { Step, STEPS } from './constants';
import {
  IncidentDetails,
  ReporterInformation,
  ReviewAndSubmit,
  Overview,
} from './steps';
import SubmittedSuccessfully from './SubmittedSuccessfully';

export const NewClaimForm = ({
  editingExistingDraft = false,
  onSubmit,
  onSaveDraft = () => {},
  onInvalidForm,
  isSubmittingFNOL,
  isSavingDraft = false,
  saveDraftEnabled = false,
  submittedSuccessfully,
}: {
  editingExistingDraft?: boolean;
  onSubmit: (data: ClaimType) => void;
  onSaveDraft?: (data: ClaimType) => void;
  onInvalidForm: () => void;
  onCreateError: () => void;
  storeFnolMutation: any;
  isSubmittingFNOL: boolean;
  isSavingDraft?: boolean;
  saveDraftEnabled?: boolean;
  submittedSuccessfully: boolean;
}) => {
  const { getValues, handleSubmit, isLoadingDraftFnol } =
    useContext(NewClaimContext);
  const componentRef = useRef<null | HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(-1);
  const [savedAsDraft, setSavedAsDraft] = useState<boolean>(false);

  const currentStep = STEPS[currentStepIndex];

  useEffect(() => {
    if (editingExistingDraft) {
      setCurrentStepIndex(2);
    }
  }, [editingExistingDraft]);

  const scrollToTop = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const screens: Record<Step, ReactNode> = {
    'incident-details': <IncidentDetails />,
    'reporter-information': <ReporterInformation />,
    'review-and-submit': (
      <ReviewAndSubmit
        onEdit={(step) => {
          const index = STEPS.findIndex((s) => s.key === step);
          setCurrentStepIndex(index);
          scrollToTop();
        }}
      />
    ),
  };

  return (
    <>
      <Show
        when={!submittedSuccessfully}
        fallback={<SubmittedSuccessfully isDraft={savedAsDraft} />}
      >
        <div className="flex flex-col mt-8 md:flex-row" ref={componentRef}>
          <Show
            when={!isLoadingDraftFnol}
            fallback={
              <div className="flex justify-center items-center w-full h-full">
                <CircularProgress />
              </div>
            }
          >
            <div>
              <Show when={currentStepIndex >= 0}>
                <div className="flex-1 p-4 pt-4 mb-4 overflow-auto border-none rounded-lg shadow-none bg-primary-extraLight md:pl-8 md:pr-20 md:py-10">
                  <div className="lg:hidden">
                    <HorizontalStepper
                      dataTestId="horizontal-stepper"
                      steps={STEPS}
                      activeStep={currentStepIndex}
                      onChange={(selectedStepIndex) => {
                        setCurrentStepIndex(selectedStepIndex);
                      }}
                    />
                  </div>
                  <div className="hidden lg:block">
                    <VerticalStepper
                      dataTestId="vertical-stepper"
                      steps={STEPS}
                      activeStep={currentStepIndex}
                      onChange={(selectedStepIndex) => {
                        setCurrentStepIndex(selectedStepIndex);
                      }}
                    />
                  </div>
                </div>
              </Show>
            </div>

            <div className="w-full p-8 pt-0 md:p-10 md:pt-0">
              <Show when={currentStepIndex < 0}>
                <Overview />
              </Show>

              <Show when={currentStepIndex >= 0}>
                <div className="w-full">
                  <div className="flex flex-col">
                    {screens[currentStep?.key]}
                  </div>
                </div>
              </Show>
            </div>
          </Show>
        </div>

        <AppBar
          color="inherit"
          elevation={1}
          position="fixed"
          sx={{ top: 'auto', bottom: 0, py: 1 }}
        >
          <Container className="flex justify-end">
            <form onSubmit={handleSubmit(onSubmit, onInvalidForm)}>
              <Show when={currentStepIndex > 0}>
                <Button
                  className="mr-4"
                  data-testid="previous-button"
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    setCurrentStepIndex(Math.max(currentStepIndex - 1, 0));
                    scrollToTop();
                  }}
                  startIcon={<HiArrowLeft />}
                >
                  Previous
                </Button>
              </Show>

              <Show when={saveDraftEnabled && currentStepIndex >= 0}>
                <Button
                  className="mr-4"
                  disabled={isSavingDraft}
                  onClick={() => {
                    setSavedAsDraft(true);
                    onSaveDraft(getValues());
                  }}
                  variant="outlined"
                >
                  Save as draft
                  <Show when={isSavingDraft}>
                    <CircularProgress className="absolute" size={24} />
                  </Show>
                </Button>
              </Show>

              <Show when={currentStepIndex !== STEPS.length - 1}>
                <Button
                  className="mr-16 lg:mr-8"
                  data-testid="next-button"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    setCurrentStepIndex(
                      Math.min(currentStepIndex + 1, STEPS.length - 1),
                    );
                    scrollToTop();
                  }}
                  endIcon={<HiArrowRight />}
                >
                  {currentStepIndex < 0 ? 'Start' : 'Proceed'}
                </Button>
              </Show>

              <Show when={currentStepIndex === STEPS.length - 1}>
                <Button
                  className="mr-16 lg:mr-8"
                  data-testid="submit-button"
                  disabled={isSubmittingFNOL}
                  type="submit"
                  variant="contained"
                >
                  Submit
                  <Show when={isSubmittingFNOL}>
                    <CircularProgress className="absolute" size={24} />
                  </Show>
                </Button>
              </Show>
            </form>
          </Container>
        </AppBar>
      </Show>
    </>
  );
};
