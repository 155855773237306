import { Button } from '@material-ui/core';
import { useContext } from 'react';
import SubmitSuccess from '../../assets/icons/submit-success.svg?react';
import { NewClaimContext } from './contexts/newClaim';

interface SubmittedSuccessfullyProps {
  isDraft: boolean;
}

export default function SubmittedSuccessfully({
  isDraft,
}: SubmittedSuccessfullyProps) {
  const { viewAllClaimsLink } = useContext(NewClaimContext);

  return (
    <div
      className="flex flex-col items-center justify-center w-full gap-10 mt-10"
      data-testid="submitted-successfully"
    >
      <SubmitSuccess />
      <h1 className="text-3xl font-bold" data-testid="claim-submitted-title">
        {isDraft
          ? 'Your draft FNOL has been saved!'
          : 'Your claim has been submitted!'}
      </h1>
      <p
        className="text-base font-light text-center text-secondary-dark w-80"
        data-testid="claim-submitted-description"
      >
        {isDraft
          ? 'You can continue editing your draft later.'
          : "Check your inbox for an email confirmation with the submission details. Next, we'll review the information and follow up within 1 business day."}
      </p>

      <a
        href={`${viewAllClaimsLink}?tab=FNOL&testFnols=false&fnolType=${
          isDraft ? 'draft' : 'submitted'
        }`}
      >
        <Button
          data-testid="submit-fnol-button"
          type="button"
          variant="contained"
        >
          {isDraft ? 'View Saved Drafts' : 'View All Claims'}
        </Button>
      </a>
    </div>
  );
}
