import { useCallback, useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { constants } from '@nirvana/ui-kit';
import DeleteIcon from '../../../assets/icons/delete.svg?react';
import FileUploadIcon from '../../../assets/icons/upload-icon.svg?react';
import {
  NewClaimContext,
  DESCRIPTION_MAX_LENGTH,
  Attachment,
} from '../contexts/newClaim';
import { policyCoveragesMapping } from '../constants';
import { FileUploadDialog } from './FileUploadDialog';
import { FileListItem } from './FileListItem';
import { DateTimePicker } from './DateTimePicker';

export const IncidentDetails = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    policies,
    isLoadingPolicies,
    signFiles,
    rejectedAttachments,
  } = useContext(NewClaimContext);
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  const form = getValues();

  const lossDate = watch('lossDate');

  const noPolicies = (policies ?? []).length === 0;
  const policyNumber = watch('policyNumber');

  const handlePresignedURLs = useCallback(
    (files: File[]) => signFiles(files),
    [signFiles],
  );

  /**
   * handleSetAttachments updates the attachments array in the form's state, like React's setState.
   * Since react-hook-form's setValue doesn't accept an updater function like setState, we retrieve
   * the latest attachments using getValues('attachments') before replacing the form state with them.
   *
   * While this might seem overkill, it does solve important edge cases. For example, if we were to
   * just accept the FileUploadDialog.onDrop's attachments living within each of their closures, we
   * would risk having race conditions, as successive executions of the onDrop handler will have a
   * different version of the form's attachments according to when they started executing (this is
   * especially relevant for slow connections).
   *
   * For example:
   *  T0 -> attachments = []
   *  T1 -> onDrop1 starts execution and setAttachments([file1, file2])
   *        form state attachments become [file1, file2]
   *  T2 -> onDrop1 still executing, and onDrop2 starts execution in parallel and setAttachments([file1, file2, file3, file4])
   *        form state attachments are overwritten and become [file1, file2, file3, file4]
   *  T3 -> onDrop1 finishes executing (whether successful or failure) and setAttachments([file1', file2'])
   *        form state attachments are overwritten and become [file1', file2'] <- notice wrong state
   *  T4 -> onDrop2 finishes executing (whether successful or failure) and setAttachments([file1, file2, file3', file4'])
   *        form state attachments are overwritten and become [file1, file2, file3', file4'] <- notice wrong state
   */
  const handleSetAttachments = useCallback(
    (updater: (prevAttachments: Attachment[]) => Attachment[]) => {
      const allAttachments = getValues('attachments');
      setValue('attachments', updater(allAttachments));
    },
    [getValues, setValue],
  );

  const removeAttachment = (attachment: Attachment) => {
    handleSetAttachments((prev) =>
      prev.filter(({ file: { name } }) => name !== attachment.file.name),
    );
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-3xl font-bold ">Incident Details</h3>

      <div className="flex flex-col w-full p-8 mt-8 bg-white rounded-lg shadow">
        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">
            Incident Time and Location
          </p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            The date and time of the incident, and where it occurred.
          </p>
        </div>

        <div className="w-full mt-4">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex-1">
              <InputLabel
                required
                className="mb-2 text-sm font-normal text-primary-main text-normal"
              >
                Time of Incident
              </InputLabel>

              <DateTimePicker
                value={lossDate}
                onChange={(v) => setValue('lossDate', v)}
              />
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel
                required
                className="mb-2 text-sm font-normal text-primary-main text-normal"
              >
                State
              </InputLabel>

              <div className="flex" data-testid="loss-state-select">
                <Select
                  {...register('lossState')}
                  fullWidth
                  value={watch('lossState')}
                  variant="outlined"
                >
                  {constants.usStates.map(
                    (state: { name: string; code: string }) => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </div>
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                Location Details
              </InputLabel>
              <OutlinedInput
                {...register('lossLocation')}
                placeholder="Enter closest city, landmark, etc."
                fullWidth
              />
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Policy Information</p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            Verify the policy number to which this incident is related to. If
            you&apos;re unsure, just select Auto Liability.
          </p>
        </div>

        <div className="w-2/4 mt-4">
          <InputLabel
            required
            className="mb-2 text-sm font-normal text-primary-main text-normal"
          >
            Policy Number
          </InputLabel>

          <div className="flex" data-testid="policy-number-select">
            <Select
              {...register('policyNumber')}
              fullWidth
              value={policyNumber}
              variant="outlined"
              disabled={isLoadingPolicies || noPolicies}
              displayEmpty={noPolicies}
            >
              {noPolicies ? (
                <div>No active policies at incident time</div>
              ) : (
                policies?.map((policy) => (
                  <MenuItem
                    key={policy.id}
                    value={policy.policyNumber}
                    onClick={() =>
                      setValue('lineOfBusiness', policy.coverages[0])
                    }
                  >
                    {`${policy.policyNumber} / ${
                      policyCoveragesMapping[policy.coverages[0]]
                    }`}
                  </MenuItem>
                ))
              )}
            </Select>

            <div className="w-full ml-4 max-w-10 max-h-10">
              {isLoadingPolicies && <CircularProgress className="p-2" />}
            </div>
          </div>
          {noPolicies && !isLoadingPolicies && (
            <span className="text-xs text-error-main">
              No active policies at incident time (email{' '}
              <a href="mailto:reportaclaim@nirvanatech.com">
                reportaclaim@nirvanatech.com
              </a>{' '}
              to report the incident)
            </span>
          )}
        </div>

        <Divider className="my-6" />

        <p className="text-base font-medium text-md">Vehicle Information</p>
        <div className="mt-4">
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="w-full max-w-52">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Was your vehicle involved?
                </InputLabel>
                <RadioGroup row defaultValue={form.ownVehiclesInvolved}>
                  <FormControlLabel
                    {...register('ownVehiclesInvolved')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('ownVehiclesInvolved')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="w-full mt-4 ml-0 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Insured Vehicles (VIN)
                </InputLabel>

                {watch('insuredVehicleVins')?.map(
                  (vin: string, index: number) => (
                    <div
                      className="flex mt-4 mb-2 first:mt-0"
                      key={`insuredVin-${index}`}
                    >
                      <OutlinedInput
                        {...register(`insuredVehicleVins.${index}`)}
                        placeholder="5N1AR1NN6CC625862"
                        disabled={watch('ownVehiclesInvolved') === 'no'}
                        className="w-full max-w-52"
                        fullWidth
                        value={vin}
                      />
                      {index === 0 ? (
                        <IconButton
                          className="text-sm"
                          disabled={watch('ownVehiclesInvolved') === 'no'}
                          onClick={() => {
                            setValue('insuredVehicleVins', [
                              ...form.insuredVehicleVins,
                              '',
                            ]);
                          }}
                        >
                          <div className="flex flex-row items-center justify-center">
                            {' '}
                            <Add fontSize="small" />{' '}
                            <p className="ml-1">Add VIN</p>
                          </div>
                        </IconButton>
                      ) : (
                        <IconButton
                          className="text-sm"
                          onClick={() => {
                            setValue(
                              'insuredVehicleVins',
                              form.insuredVehicleVins.filter(
                                (_: string, i: number) => i !== index,
                              ),
                            );
                          }}
                        >
                          <DeleteIcon className="scale-100" />
                        </IconButton>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>

            <div className="flex flex-col mt-4 md:flex-row">
              <div className="w-full max-w-52">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Were other vehicles involved?
                </InputLabel>
                <RadioGroup row defaultValue={form.otherVehiclesInvolved}>
                  <FormControlLabel
                    {...register('otherVehiclesInvolved')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('otherVehiclesInvolved')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="w-full mt-4 ml-0 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Other Vehicles (License Plate)
                </InputLabel>

                {watch('otherVehicleVins')?.map(
                  (vin: string, index: number) => (
                    <div
                      className={`flex mb-2 ${index === 0 ? 'mt-4' : ''}`}
                      key={`insuredVin-${index}`}
                    >
                      <OutlinedInput
                        {...register(`otherVehicleVins.${index}`)}
                        disabled={watch('otherVehiclesInvolved') === 'no'}
                        placeholder="JHL4783"
                        className="max-w-52"
                        fullWidth
                        value={vin}
                      />
                      {index === 0 ? (
                        <IconButton
                          className="text-sm"
                          disabled={watch('otherVehiclesInvolved') === 'no'}
                          onClick={() => {
                            setValue('otherVehicleVins', [
                              ...form.otherVehicleVins,
                              '',
                            ]);
                          }}
                        >
                          <div className="flex flex-row items-center justify-center">
                            {' '}
                            <Add fontSize="small" />{' '}
                            <p className="ml-1">Add Vehicle</p>
                          </div>
                        </IconButton>
                      ) : (
                        <IconButton
                          className="text-sm"
                          onClick={() => {
                            setValue(
                              'otherVehicleVins',
                              form.otherVehicleVins.filter(
                                (_: string, i: number) => i !== index,
                              ),
                            );
                          }}
                        >
                          <DeleteIcon className="scale-100" />
                        </IconButton>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <p className="text-base font-medium text-md">Law Enforcement</p>
        <div className="mt-4">
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-1 w-full">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Was law enforcement present at the scene of the incident?
                </InputLabel>
                <RadioGroup row defaultValue={form.police.onTheScene}>
                  <FormControlLabel
                    {...register('police.onTheScene')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('police.onTheScene')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>

              <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Was anyone injured?
                </InputLabel>

                <RadioGroup row defaultValue={form.injureds}>
                  <FormControlLabel
                    {...register('injureds')}
                    control={<Radio />}
                    label="Yes"
                    value="yes"
                  />

                  <FormControlLabel
                    {...register('injureds')}
                    control={<Radio />}
                    label="No"
                    value="no"
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="flex flex-col mt-4 md:flex-row">
              <div className="flex-1">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Law Enforcement Agency Name
                </InputLabel>
                <OutlinedInput
                  {...register('police.agencyName')}
                  disabled={watch('police.onTheScene') === 'no'}
                  fullWidth
                />
              </div>

              <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-12">
                <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                  Report Number
                </InputLabel>
                <OutlinedInput
                  {...register('police.reportNumber')}
                  disabled={watch('police.onTheScene') === 'no'}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Incident Description</p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            Share as much detail as possible to speed up the claim process.
          </p>
        </div>

        <div className="w-full mt-4">
          <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
            What happened?
          </InputLabel>

          <OutlinedInput
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: DESCRIPTION_MAX_LENGTH }}
            {...register('description')}
          />
          <p className="mt-1 mr-2 text-xs text-end text-text-hint">
            {watch('description')?.length || 0}/{DESCRIPTION_MAX_LENGTH}
          </p>
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">
            Supporting Reports, Images and Videos
          </p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            Upload any documentation that you have about this incident so far:
            images, police reports, receipts, other documents.
          </p>
        </div>

        <div className="flex gap-10 mt-4">
          <div>
            <Button
              onClick={() => setIsFileUploadDialogOpen(true)}
              startIcon={<FileUploadIcon />}
              variant="outlined"
            >
              Upload
            </Button>

            <FileUploadDialog
              attachments={watch('attachments')}
              rejected={rejectedAttachments}
              isOpen={isFileUploadDialogOpen}
              onClose={() => setIsFileUploadDialogOpen(false)}
              handlePresignedURLs={handlePresignedURLs}
              setAttachments={handleSetAttachments}
            />
          </div>
          <div className="flex flex-col">
            {form.attachments.map((attachment: Attachment) => (
              <div
                className="p-1 mb-2 border border-solid rounded-md border-primary-tint3"
                key={`file-${attachment.file.name}`}
                onClick={
                  attachment.downloadUrl
                    ? () => window.open(attachment.downloadUrl, '_blank')
                    : undefined
                }
                style={{
                  cursor: attachment.downloadUrl ? 'pointer' : 'default',
                }}
              >
                <FileListItem
                  file={attachment.file}
                  onRemove={() => removeAttachment(attachment)}
                />
              </div>
            ))}
          </div>
        </div>
        {rejectedAttachments && rejectedAttachments.length > 0 ? (
          <span className="text-error-main">
            Some of the uploaded files are not supported. Click the Upload
            button to see them.
          </span>
        ) : null}
      </div>
    </div>
  );
};
