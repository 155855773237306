import * as React from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import { NavLink, useLocation, Link, useParams } from 'react-router-dom';
import { analytics } from 'src/helpers';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { BrandingTypes, Branding, Show, Chip } from '@nirvana/ui-kit';
import useAuth, { isAnonymousUser, isFleetUser } from 'src/hooks/useAuth';
import { useDOTSpecificFeature } from 'src/hooks/useDotSpecificFeature';
import { Tsp, useFleetDetailsQuery } from 'src/types/graphql-types';
import { useAnalytics } from 'src/helpers/analytics';
import { getPages, RouteBlock } from './tabs';

/**
 * Sidebar is a part of the AppShell which renders,
 * Tabs using `NavLink` to navigate through pages
 */
export default function Sidebar({
  className,
  showLogo = false,
}: {
  className?: string;
  showLogo?: boolean;
}) {
  const location = useLocation();
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();

  // The reportId param is now most often a DOT number, but it can still occasionally be a safety report UUID.
  const { reportId: dotOrReportID = '' } = useParams<{ reportId: string }>();
  const { data } = useFleetDetailsQuery({
    variables: { reportId: dotOrReportID },
    skip: !dotOrReportID,
  });
  const { TspProvider } = data?.fleetSafetyReport || {};
  const showPerksTab = useFeatureEnabled(Feature.PERKS_TAB);
  const showTrainingTab = useFeatureEnabled(Feature.TRAINING_TAB);
  const { enabled: showClaims } = useDOTSpecificFeature(Feature.SHOW_CLAIMS, {
    allowUserOverride: true,
  });
  const { enabled: showDrivers } = useDOTSpecificFeature(Feature.SHOW_DRIVERS, {
    allowUserOverride: true,
  });
  const pages = getPages(data?.fleetSafetyReport?.dotNumber || dotOrReportID, {
    showPerksTab,
    showTrainingTab,
    showClaims,
    showDrivers:
      showDrivers &&
      [Tsp.TspSamsara, Tsp.TspSamsaraSafety].includes(TspProvider as Tsp),
    isFleetUser: isFleetUser(user),
    isAnonymousUser: isAnonymousUser(user),
  });

  const handleLinkClick = (route: RouteBlock) => () => {
    trackEvent({
      event: analytics.EventTrack.SidebarLinkSelect,
      properties: {
        module: route.id,
      },
    });
  };

  return (
    <div id="sidebar" className={clsx('h-full py-4 bg-white', className)}>
      <Show when={showLogo}>
        <Link className="flex justify-center w-full pb-4" to="/">
          <Branding brandingType={BrandingTypes.SafetyInverted} />
        </Link>
      </Show>
      {pages.map((page) => {
        const isActive = location.pathname === page.defaultRoute;

        return (
          <React.Fragment key={page.id}>
            <NavLink
              to={page.defaultRoute}
              className={({ isActive }) =>
                clsx(
                  'flex items-center py-3 pl-4 pr-16 space-x-3 border-l-4 border-white text-text-secondary hover:bg-gray-50',
                  {
                    'bg-primary-extraLight text-primary-dark border-l-4 !border-primary-dark hover:bg-primary-tint1 font-semibold':
                      isActive,
                  },
                )
              }
              onClick={handleLinkClick(page)}
            >
              {isActive ? page.activeIcon : page.icon}
              <span>{page.title}</span>
              {page.new && <Chip label="New" color="gold" size="small" />}
            </NavLink>
            {!!page.routes && (
              <Collapse in={page.defaultExpanded}>
                <div className="pl-11">
                  <div className="border-l border-primary-tint3">
                    {page.routes.map((route) => {
                      return (
                        <NavLink
                          key={route.id}
                          to={route.defaultRoute}
                          className={({ isActive }) =>
                            clsx(
                              'flex items-center py-2 pl-4 pr-16 space-x-3 border-l-4 border-white text-text-secondary hover:bg-gray-50 font-regular',
                              {
                                'text-primary-dark border-l-4 !border-primary-dark hover:bg-primary-tint1 font-regular':
                                  isActive,
                              },
                            )
                          }
                          onClick={handleLinkClick(route)}
                        >
                          <span>{route.title}</span>
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
